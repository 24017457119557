import {useCallback, useState, useEffect} from 'react'

import './styles.css'

/**
 *
 * @param {{link: string; label: string; selected: boolean}[]} items
 * @returns {React.Node}
 */
export const Sidebar = ({items: initialItems}) => {
	const [items, setItems] = useState(initialItems)

	const handleClick = useCallback(clickedItem => {
		setItems(prev => {
			return prev.map(item => {
				if (item.selected) {
					return {
						...item,
						selected: false
					}
				}

				if (item.link === clickedItem.link) {
					return {
						...item,
						selected: true
					}
				}

				return item
			})
		})
	}, [])

	const headerHeight = 93 // Altura do header fixo

	useEffect(() => {
		// Adiciona o efeito de scroll suave com ajuste para header fixo
		const handleSmoothScroll = event => {
			event.preventDefault()
			const targetId = event.currentTarget.getAttribute('href').substring(1)
			const targetElement = document.getElementById(targetId)

			if (targetElement) {
				const targetPosition = targetElement.offsetTop - headerHeight - 40

				window.scrollTo({
					top: targetPosition,
					behavior: 'smooth'
				})
			}
		}
		// Seleciona todos os links do índice e adiciona o evento de clique
		const anchors = document.querySelectorAll('.sidebar a')
		anchors.forEach(anchor => {
			anchor.addEventListener('click', handleSmoothScroll)
		})

		// Remove event listeners quando o componente for desmontado
		return () => {
			anchors.forEach(anchor => {
				anchor.removeEventListener('click', handleSmoothScroll)
			})
		}
	}, [])

	return (
		<aside className="sidebar">
			<h3>Índice</h3>
			<div>
				{items.map(item => (
					<a
						href={item.link}
						className={item.selected ? 'selected' : undefined}
						onClick={() => handleClick(item)}
					>
						{item.label}
					</a>
				))}
			</div>
		</aside>
	)
}

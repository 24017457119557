import * as React from 'react'
import {MdMenu} from 'react-icons/md'
import {slide as Menu} from 'react-burger-menu'

export function MenuList() {
	const showSettings = event => {
		event.preventDefault()
	}

	return (
		<box className="menu-container" style={{display: 'none'}}>
			<Menu right>
				<a
					id="home"
					className="menu-item"
					href="https://dashboard.excofy.com/pt/login"
					target="_blank"
					rel="noreferrer"
				>
					ENTRAR
				</a>
				<a
					id="about"
					className="menu-item"
					href="https://dashboard.excofy.com/pt/sign-up"
					target="_blank"
					rel="noreferrer"
				>
					CADASTRE-SE
				</a>
			</Menu>
			<button onClick={showSettings}>
				<MdMenu color="#212121" fontSize="2rem" />
			</button>
		</box>
	)
}

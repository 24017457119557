export const SignUpButton = () => {
	return (
		<a
			href="https://dashboard.excofy.com/pt/sign-up"
			style={{
				cursor: 'pointer',
				backgroundColor: 'rgb(241, 187, 12)',
				borderRadius: 4,
				padding: '8px 22px',
				height: 44,
				fontWeight: 500,
				display: 'flex',
				alignItems: 'center'
			}}
		>
			CADASTRE-SE
		</a>
	)
}

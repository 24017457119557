import React from 'react'
import ReactDOM from 'react-dom'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'

import './style.css'

import Frame1Home from './views/frame1-home'
import NotFound from './views/not-found'
import TermsOfUse from './views/terms-of-use'
import PrivacyPolicy from './views/privacy-policy'

const App = () => {
	return (
		<Router>
			<Switch>
				<Route component={Frame1Home} exact path="/" />
				<Route component={TermsOfUse} path="/terms-of-use" />
				<Route component={PrivacyPolicy} path="/privacy-policy" />
				<Route component={NotFound} path="**" />
				<Redirect to="**" />
			</Switch>
		</Router>
	)
}

ReactDOM.render(<App />, document.getElementById('app'))

import {useState} from 'react'

export const MailForm = () => {
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [email, setEmail] = useState()
	const [phoneNumber, setPhoneNumber] = useState()

	return (
		<div id="mc_embed_shell">
			<div id="mc_embed_signup">
				<form
					action="https://excofy.us14.list-manage.com/subscribe/post?u=7903200eab2d1f3fe6c490cb4&amp;id=2939067a23&amp;f_id=00dfc2e1f0"
					method="post"
					name="mc-embedded-subscribe-form"
					target="_blank"
				>
					<div className="frame1-home-form">
						<div className="frame1-home-row4">
							<div className="frame1-home-input">
								<span className="frame1-home-text095 14Regular">
									<span>Nome</span>
								</span>
								<input
									type="text"
									name="FNAME"
									className="frame1-home-input1"
									value={firstName}
									onChange={e => setFirstName(e.target.value)}
								/>
							</div>
							<div className="frame1-home-input2">
								<span className="frame1-home-text097 14Regular">
									<span>Sobrenome</span>
								</span>
								<input
									type="text"
									name="LNAME"
									className="frame1-home-input3"
									value={lastName}
									onChange={e => setLastName(e.target.value)}
								/>
							</div>
							<div className="frame1-home-input4">
								<span className="frame1-home-text099 14Regular">
									<span>Email</span>
								</span>
								<input
									type="email"
									name="EMAIL"
									className="frame1-home-input5"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</div>
							<div className="frame1-home-input6">
								<span className="frame1-home-text101 14Regular">
									<span>Telefone</span>
								</span>
								<input
									type="text"
									name="PHONE"
									className="frame1-home-input7"
									value={phoneNumber}
									onChange={e => setPhoneNumber(e.target.value)}
								/>
							</div>
						</div>
						<button
							type="submit"
							className="frame1-home-primary-button"
							style={{cursor: 'pointer'}}
						>
							<span className="frame1-home-text004">ENVIAR</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

import {Header} from '../../components/header/index'
import {Sidebar} from '../../components/sidebar/index'
import {Footer} from '../../components/footer/index'

import './styles.css'

export default function PrivacyPolicy() {
	return (
		<div id="privacy-policy">
			<Header />
			<main>
				<Sidebar
					items={[
						{label: 'Introdução', link: '#title', selected: true},
						{
							label: 'Definições',
							link: '#definicoes',
							selected: false
						},
						{
							label: 'Informações Gerais',
							link: '#informacoes-gerais',
							selected: false
						},
						{
							label: 'Política para Menores de Idade',
							link: '#politica-para-menores-de-idade',
							selected: false
						},
						{
							label: 'Dados Coletados e Fornecidos',
							link: '#dados-coletados-e-fornecidos',
							selected: false
						},
						{
							label: 'Finalidade dos Dados dos Usuários',
							link: '#finalidade-dos-dados-dos-usuarios',
							selected: false
						},
						{
							label: 'Compartilhamento dos Dados dos Usuários',
							link: '#compartilhamento-dos-dados-dos-usuarios',
							selected: false
						},
						{
							label: 'Retenção dos Dados dos Usuários',
							link: '#retencao-dos-dados-dos-usuarios',
							selected: false
						},
						{
							label: 'Direitos do Usuário',
							link: '#direitos-do-usuario',
							selected: false
						},
						{
							label: 'Segurança dos Dados dos Usuários',
							link: '#seguranca-dos-dados-dos-usuarios',
							selected: false
						},
						{
							label: 'Política de Cookies',
							link: '#politica-de-cookies',
							selected: false
						},
						{
							label: 'Alterações na Política de Privacidade',
							link: '#alteracoes-na-politica-de-privacidade',
							selected: false
						},
						{label: 'Contato', link: '#contato', selected: false},
						{label: 'Vigência', link: '#vigencia', selected: false}
					]}
				/>
				<div className="terms-of-use--content">
					<div className="privacy-policy--container">
						<h1 id="title" className="privacy-policy--title">
							Políticas de Privacidade
						</h1>
						<div className="privacy-policy--sections-container">
							<div className="privacy-policy--section">
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										<b>Bem-vindo(a) a Excofy!</b>
									</p>
									<p className="privacy-policy-paragraph">
										A EXCOFY, inscrita no CNPJ 55.390.780/0001-15, é uma
										plataforma de serviços online que oferece uma vasta gama de
										soluções para facilitar a venda de experiências, produtos
										digitais e serviços. Nosso compromisso é garantir que seus
										dados pessoais sejam tratados com segurança e transparência,
										em conformidade com a Lei Geral de Proteção de Dados (LGPD).
									</p>
									<p className="privacy-policy-paragraph">
										Esta Política de Privacidade explica detalhadamente como
										coletamos, utilizamos, compartilhamos e protegemos as
										informações pessoais dos nossos usuários. Ela também
										descreve seus direitos em relação aos seus dados pessoais e
										como você pode exercê-los. Na EXCOFY, valorizamos sua
										privacidade e nos esforçamos para criar um ambiente seguro e
										confiável para todos os nossos usuários.
									</p>
									<p className="privacy-policy-paragraph">
										Agradecemos por escolher a EXCOFY e estamos aqui para
										assegurar que suas interações conosco sejam protegidas e em
										conformidade com as melhores práticas de proteção de dados.
									</p>
								</div>
							</div>
							<section id="definicoes" className="privacy-policy--section">
								<h2 className="privacy-policy--subtitle">1. Definições</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Estas definições são fundamentais para entender o tratamento
										de dados pessoais na EXCOFY e estão alinhadas com os
										princípios da LGPD:
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Agentes de tratamento:</b> O controlador e o operador.
										</li>
										<li>
											<b>Anonimização:</b> Utilização de meios técnicos
											razoáveis e disponíveis no momento do tratamento, por meio
											dos quais um dado perde a possibilidade de associação,
											direta ou indireta, a um indivíduo.
										</li>
										<li>
											<b>Autoridade nacional:</b> Órgão da administração pública
											responsável por zelar, implementar e fiscalizar o
											cumprimento desta Lei em todo o território nacional
											(ANPD).
										</li>
										<li>
											<b>Banco de dados:</b> Conjunto estruturado de dados
											pessoais, estabelecido em um ou vários locais, em suporte
											eletrônico ou físico.
										</li>
										<li>
											<b>Bloqueio:</b> Suspensão temporária de qualquer operação
											de tratamento, mediante guarda do dado pessoal ou do banco
											de dados.
										</li>
										<li>
											<b>Consentimento:</b> Manifestação livre, informada e
											inequívoca pela qual o titular concorda com o tratamento
											de seus dados pessoais, para uma finalidade determinada.
										</li>
										<li>
											<b>Controlador:</b> Pessoa natural ou jurídica, de direito
											público ou privado, a quem competem as decisões referentes
											ao tratamento de dados pessoais.
										</li>
										<li>
											<b>Dado anonimizado:</b> Dado relativo a titular que não
											possa ser identificado, considerando a utilização de meios
											técnicos razoáveis e disponíveis na ocasião de seu
											tratamento.
										</li>
										<li>
											<b>Dado pessoal:</b> Informação relacionada a pessoa
											natural identificada ou identificável.
										</li>
										<li>
											<b>Dado pessoal sensível:</b> Dado sobre origem racial ou
											étnica, convicção religiosa, opinião política, filiação a
											sindicato ou a organização de caráter religioso,
											filosófico ou político, dado referente à saúde ou à vida
											sexual, dado genético ou biométrico.
										</li>
										<li>
											<b>Eliminação:</b> Exclusão de dado ou de conjunto de
											dados armazenados em banco de dados, independentemente do
											procedimento empregado.
										</li>
										<li>
											<b>Encarregado:</b> Pessoa indicada pelo controlador e
											operador para atuar como canal de comunicação entre o
											controlador, os titulares dos dados e a Autoridade
											Nacional de Proteção de Dados (ANPD).
										</li>
										<li>
											<b>Órgão de pesquisa:</b> Órgão ou entidade da
											administração pública direta ou indireta ou pessoa
											jurídica de direito privado sem fins lucrativos,
											legalmente constituída sob as leis brasileiras, com sede e
											foro no País, que inclua em sua missão institucional ou em
											seu objetivo social ou estatutário a pesquisa básica ou
											aplicada de caráter histórico, científico, tecnológico ou
											estatístico.
										</li>
										<li>
											<b>Operador:</b> Pessoa natural ou jurídica, de direito
											público ou privado, que realiza o tratamento de dados
											pessoais em nome do controlador.
										</li>
										<li>
											<b>Relatório de impacto à proteção de dados pessoais:</b>
											Documentação do controlador que contém a descrição dos
											processos de tratamento de dados pessoais que podem gerar
											riscos às liberdades civis e aos direitos fundamentais.
										</li>
										<li>
											<b>Titular:</b> Pessoa natural a quem se referem os dados
											pessoais que são objeto de tratamento.
										</li>
										<li>
											<b>Transferência internacional de dados:</b> Transferência
											de dados pessoais para país estrangeiro ou organismo
											internacional do qual o país seja membro.
										</li>
										<li>
											<b>Tratamento:</b> Toda operação realizada com dados
											pessoais, como coleta, produção, recepção, classificação,
											utilização, acesso, reprodução, transmissão, distribuição,
											processamento, arquivamento, armazenamento, eliminação,
											avaliação ou controle da informação, modificação,
											comunicação, transferência, difusão ou extração.
										</li>
										<li>
											<b>Uso compartilhado de dados:</b> Comunicação, difusão,
											transferência internacional, interconexão de dados
											pessoais ou tratamento compartilhado de bancos de dados
											pessoais por órgãos e entidades públicos no cumprimento de
											suas competências legais, ou entre esses e entes privados,
											reciprocamente, com autorização específica.
										</li>
									</ul>
								</div>
							</section>
							<section
								id="informacoes-gerais"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									2. Informações Gerais
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										<b>2.1. Classe de Usuários:</b> Os usuários da EXCOFY são
										classificados como Vendedores, Compradores, Afiliados e
										Terceiros envolvidos em relações comerciais, conforme
										definido nos{' '}
										<a href="/terms-of-use" className="link">
											Termos de Uso
										</a>
										.
									</p>

									<p className="privacy-policy-paragraph">
										<b>2.2. Formas de Tratamento de Dados:</b> A EXCOFY trata os
										dados dos usuários de acordo com sua função na plataforma:
									</p>

									<ol className="privacy-policy-ordered-list">
										<li>
											<b>Página de Pagamento:</b> Ferramenta que Vendedores
											utilizam para que Compradores adquiram experiências,
											produtos digitais ou serviços.
										</li>
										<li>
											<b>Painel de Controle:</b> Plataforma usada pelos
											Vendedores para envio e gerenciamento de conteúdos,
											acompanhamento de faturamento das vendas, gerenciamento de
											Compradores, configurações de dados da empresa,
											configurações de acesso e serviços de pagamento.
										</li>
										<li>
											<b>Área do Cliente:</b> Espaço onde Compradores gerenciam
											assinaturas e compras, acessível via email enviado após a
											compra.
										</li>
										<li>
											<b>Área do Cliente:</b> Espaço onde Compradores gerenciam
											assinaturas e compras, acessível via email enviado após a
											compra.
										</li>
										<li>
											<b>Autenticação de Acesso:</b> Tecnologia que unifica o
											acesso a todas as ferramentas da Plataforma EXCOFY.
										</li>
										<li>
											<b>Página de Vendas:</b> A plataforma permite a oferta de
											conteúdos digitais em diversos formatos como vídeo, áudio,
											texto, imagens, criados pelo Vendedor ou licenciados por
											terceiros.
										</li>
										<li>
											<b>Compartilhamento com Terceiros:</b> Coletamos dados
											pessoais para disponibilizar, gerenciar, ofertar, comprar
											e acessar conteúdos. Alguns desses dados são
											compartilhados com terceiros para garantir a
											funcionalidade da plataforma.
										</li>
									</ol>
								</div>
							</section>
							<section
								id="politica-para-menores-de-idade"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									3. Política para Menores de Idade
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										<b>3.1. Tratamento de Dados Pessoais de Menores:</b> A
										EXCOFY está comprometida com a proteção da privacidade dos
										menores de idade e não coleta ou solicita intencionalmente
										dados pessoais de menores de 13 anos. Se tomarmos
										conhecimento de que dados pessoais de menores de 13 anos
										foram coletados inadvertidamente, tomaremos as seguintes
										medidas:
									</p>
									<ol className="privacy-policy-ordered-list">
										<li>
											<b>Cancelamento da Conta:</b> A conta criada na Plataforma
											será imediatamente cancelada.
										</li>
										<li>
											<b>Exclusão de Dados Pessoais:</b> Os dados pessoais
											coletados serão excluídos de nossos sistemas de maneira
											segura e definitiva.
										</li>
										<li>
											<b>Retenção de Dados Essenciais:</b> Manteremos apenas os
											dados estritamente necessários para prevenir novas
											tentativas de cadastro, garantindo a proteção contínua e o
											cumprimento das nossas políticas.
										</li>
									</ol>
									<p className="privacy-policy-paragraph">
										Os responsáveis legais por menores que tenham qualquer
										dúvida ou preocupação sobre o tratamento de dados podem
										entrar em contato conosco através do e-mail:
										suporte@excofy.com.
									</p>
								</div>
							</section>
							<section
								id="dados-coletados-e-fornecidos"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									4. Dados Coletados e Fornecidos
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										<b>4.1. Tipos de Dados Coletados:</b> Coletamos dados
										diretamente dos usuários, informações inseridas pelos mesmos
										ou dados sobre o consumo do conteúdo, além de alguns dados
										coletados automaticamente, como informações sobre o
										dispositivo e interações com nossos serviços.
									</p>
									<p className="privacy-policy-paragraph">
										<b>4.2. Dados Fornecidos pelo Usuário:</b> Coletamos
										diferentes dados sobre o usuário, de acordo com a forma como
										utiliza os Serviços da EXCOFY:
									</p>

									<ol className="privacy-policy-ordered-list">
										<li>
											<b>Dados de Cadastro de Compradores:</b> Nome completo,
											E-mail, Telefone, CPF ou CNPJ.
										</li>
										<li>
											<b>Dados de Cadastro de Vendedores:</b> E-mail de
											Cadastro, Senha, CNPJ, Razão Social, Nome Fantasia, Email
											da Empresa, Telefone, Endereço Completo, para cadastro na
											plataforma. Dados Bancários, Informações de Faturamento e
											Informações do Representa Legal da Empresa para ativação
											de serviços de pagamento.
										</li>
										<li>
											<b>Dados de Pagamento:</b> Dados do cartão de crédito e
											endereço de cobrança, informações estas necessárias para
											processar pagamentos.
										</li>
										<li>
											<b>Dados de Suporte:</b> Nome, E-mail, Assunto e demais
											documentos complementares para melhor atendimento.
										</li>
										<li>
											{' '}
											<b>Dados de Atividades:</b> Frequência de uso da
											Plataforma, Informação sobre o Navegador, Dados de
											Conexão, Histórico de Compras e Navegação.
										</li>
										<li>
											<b>Dados para Ações Comerciais:</b> Nome, E-mail,
											Endereço, Telefone e Celular.
										</li>
									</ol>
								</div>
							</section>
							<section
								id="finalidade-dos-dados-dos-usuarios"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									5. Finalidade dos Dados dos Usuários
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Os dados dos usuários são coletados e utilizados com o
										objetivo de proporcionar uma experiência de serviço segura,
										eficiente e personalizada. Abaixo detalhamos as diversas
										finalidades para as quais utilizamos suas informações,
										explicando como cada uma contribui para o aprimoramento dos
										nossos serviços e a proteção dos seus dados pessoais:
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.1 Fornecimento e Administração dos Serviços:</b>{' '}
										Utilizamos os dados dos usuários para oferecer e gerenciar
										os serviços disponibilizados em nossa plataforma. Isso
										inclui o processamento de pedidos, a gestão de contas, a
										execução de transações e a manutenção da funcionalidade dos
										nossos serviços, garantindo que todas as operações ocorram
										de maneira adequada e eficiente.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.2 Comunicação com o Usuário:</b> Os dados coletados são
										usados para estabelecer e manter comunicação com os
										usuários. Isso inclui o envio de atualizações sobre o status
										dos serviços, a notificação de mudanças importantes nas
										políticas e procedimentos, bem como a resposta a dúvidas e
										solicitações dos usuários através de nossos canais de
										suporte.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.3 Solução de Problemas e Suporte ao Cliente:</b>{' '}
										Utilizamos as informações dos usuários para identificar e
										resolver problemas técnicos, oferecer suporte ao cliente e
										melhorar continuamente nossos serviços. Analisamos os dados
										para diagnosticar e corrigir falhas, responder a perguntas e
										fornecer assistência personalizada.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.4 Proteção Contra Fraude e Segurança:</b> Empregamos os
										dados dos usuários para proteger a plataforma e os usuários
										contra fraudes, abusos e outras atividades ilegais ou não
										autorizadas. Monitoramos e analisamos padrões de uso para
										detectar comportamentos suspeitos e implementar medidas de
										segurança eficazes.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.5 Aprimoramento e Atualização dos Serviços:</b>{' '}
										Utilizamos os dados para avaliar o desempenho dos nossos
										serviços e identificar áreas para melhorias. Analisamos a
										maneira como os usuários interagem com a plataforma para
										atualizar funcionalidades existentes e introduzir novas
										features que atendam melhor às necessidades dos usuários.
									</p>
									<p className="privacy-policy-paragraph">
										<b>
											5.6 Análise de Uso e Tendências: Coletamos e analisamos
											dados
										</b>
										sobre como os usuários utilizam nossos serviços, incluindo
										padrões de uso e preferências. Esta análise nos ajuda a
										compreender tendências, avaliar a eficácia de campanhas e
										estratégias, e tomar decisões informadas para o
										desenvolvimento futuro da plataforma.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.7 Envio de Informações e Notificações:</b> Com o
										consentimento dos usuários, enviamos informações relevantes,
										ofertas especiais, atualizações de produtos e novidades
										sobre nossos serviços. O envio de comunicações pode incluir
										newsletters, promoções e alertas sobre novos recursos ou
										eventos.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.7 Facilitação de Operações Técnicas:</b> Os dados dos
										usuários são utilizados para manter a infraestrutura técnica
										dos nossos serviços, incluindo a gestão de servidores, a
										realização de backups e a garantia de que a plataforma
										funcione de maneira estável e eficiente.
									</p>
									<p className="privacy-policy-paragraph">
										<b>
											5.8 Movimentações Bancárias e Processamento de Pagamentos:
										</b>
										Utilizamos informações financeiras para processar
										pagamentos, realizar transações e gerenciar contas. Esses
										dados são essenciais para garantir que as transações sejam
										realizadas com segurança e que os processos financeiros
										sejam concluídos corretamente.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.9 Solicitação de Feedback:</b> Coletamos feedback dos
										usuários para avaliar a satisfação com nossos serviços e
										identificar áreas para melhorias. O feedback pode ser
										solicitado através de pesquisas, avaliações e outras formas
										de comunicação direta com os usuários.
									</p>
									<p className="privacy-policy-paragraph">
										<b>
											5.10 Identificação de Usuários Únicos Entre Dispositivos:{' '}
										</b>
										Usamos dados para identificar e distinguir usuários únicos
										entre diferentes dispositivos e sessões. Essa prática é
										importante para manter a integridade das contas e a
										personalização da experiência do usuário.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.11 Desenvolvimento de Novos Produtos e Recursos:</b>{' '}
										Utilizamos dados dos usuários para explorar oportunidades de
										desenvolvimento de novos produtos, serviços e recursos. A
										análise de feedback e tendências ajuda a criar soluções
										inovadoras que atendam às necessidades e expectativas dos
										usuários.
									</p>
									<p className="privacy-policy-paragraph">
										<b>5.12 Análise de Tendências e Tráfego:</b> Monitoramos e
										analisamos dados sobre o tráfego do site e as tendências de
										uso para otimizar a experiência do usuário e melhorar a
										eficácia das nossas estratégias de marketing e operações.
									</p>
									<p className="privacy-policy-paragraph">
										<b>
											5.13 Rastreamento de Compras e Dados sobre Utilização:
										</b>{' '}
										Coletamos informações sobre as compras realizadas e a forma
										como os serviços são utilizados para oferecer suporte
										adicional, analisar comportamentos de compra e melhorar as
										ofertas disponíveis na plataforma.
									</p>
									<p className="privacy-policy-paragraph">
										Estas finalidades são essenciais para a operação eficiente
										dos nossos serviços e para garantir que continuemos a
										oferecer uma plataforma segura e de alta qualidade para
										nossos usuários. Sempre respeitamos as leis e regulamentos
										aplicáveis em relação ao tratamento de dados pessoais e
										garantimos que suas informações sejam usadas de maneira
										transparente e responsável.
									</p>
								</div>
							</section>
							<section
								id="compartilhamento-dos-dados-dos-usuarios"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									6. Compartilhamento dos Dados dos Usuários
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										A proteção da privacidade dos nossos usuários é uma
										prioridade para nós. No entanto, em determinadas
										circunstâncias, podemos compartilhar os dados pessoais dos
										usuários com terceiros. Abaixo detalhamos as situações
										específicas em que isso pode ocorrer e as razões para tal
										compartilhamento:
									</p>

									<p className="privacy-policy-paragraph">
										<b>6.1 Com Empresas do Mesmo Grupo:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para a prestação dos serviços
											oferecidos pela nossa plataforma, a operacionalização de
											compras e o aprimoramento das funcionalidades da
											plataforma.
										</li>
										<li>
											<b>Descrição:</b> Compartilhamos dados com nossas
											subsidiárias, afiliadas e outras empresas controladas ou
											sob controle comum para melhorar a eficiência dos serviços
											prestados e garantir uma experiência de usuário mais
											integrada e personalizada.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.2 Com Vendedores:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para a disponibilização dos produtos ou
											serviços adquiridos, comunicação direta com os usuários,
											suporte ao cliente e atividades de marketing.
										</li>
										<li>
											<b>Descrição:</b> Dados dos usuários são compartilhados
											com vendedores parceiros para facilitar o processamento de
											pedidos, a entrega de produtos e serviços, bem como para
											oferecer suporte e enviar informações relevantes sobre
											ofertas e promoções.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>
											6.3 Com Instituições Financeiras (Serviços de Pagamento):
										</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para processar pagamentos de forma
											segura e eficiente.
										</li>
										<li>
											<b>Descrição:</b> Compartilhamos informações financeiras e
											de transações com provedores de serviços de pagamento e
											instituições financeiras para garantir que todas as
											transações sejam concluídas de maneira segura e conforme
											os padrões regulamentares.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.4 Com Autoridades Judiciais ou Governamentais:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para o cumprimento de obrigações legais
											e regulatórias.
										</li>
										<li>
											<b>Descrição:</b> Podemos divulgar dados pessoais dos
											usuários em resposta a solicitações de autoridades
											judiciais, policiais ou governamentais, ou quando
											acreditamos de boa fé que a divulgação é necessária para
											cumprir uma obrigação legal ou proteger nossos direitos,
											segurança ou propriedade.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>
											6.5 Com Assessores Financeiros, Contábeis, Jurídicos e
											Potenciais Investidores:
										</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para a análise e avaliação do grupo
											empresarial.
										</li>
										<li>
											<b>Descrição:</b> Dados dos usuários podem ser
											compartilhados com nossos consultores financeiros,
											contábeis e jurídicos, bem como com potenciais
											investidores, no contexto de auditorias, análises de
											negócio, e transações corporativas como fusões, aquisições
											ou vendas de ativos.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.6 Com Prestadores de Serviços:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para o desenvolvimento das atividades
											da EXCOFY e suporte operacional.
										</li>
										<li>
											<b>Descrição:</b> Compartilhamos dados com prestadores de
											serviços terceirizados que desempenham funções em nosso
											nome, como hospedagem de site, análise de dados, serviços
											de marketing, atendimento ao cliente, e assistência
											técnica. Esses terceiros são contratualmente obrigados a
											proteger a confidencialidade dos dados e a utilizá-los
											apenas para os fins especificados.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.7 Com Parceiros de Marketing e Publicidade:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para a execução de campanhas de
											marketing e publicidade direcionadas.
										</li>
										<li>
											<b>Descrição:</b> Compartilhamos informações com parceiros
											de marketing e publicidade para melhorar a relevância dos
											anúncios exibidos aos usuários e para medir a eficácia das
											campanhas publicitárias. Esses parceiros são selecionados
											cuidadosamente e estão sujeitos a obrigações contratuais
											de proteção de dados.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.8 Com Plataformas de Análise e Estatísticas:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para a realização de análises e
											estatísticas sobre o uso da nossa plataforma.
										</li>
										<li>
											<b>Descrição:</b> Utilizamos serviços de análise para
											entender melhor o comportamento dos usuários e melhorar
											nossos serviços. Esses serviços podem incluir Google
											Analytics e outras ferramentas similares, que coletam e
											processam dados de uso.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>6.9 Em Transações Comerciais:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Para facilitar transações comerciais
											como fusões, aquisições ou venda de ativos.
										</li>
										<li>
											<b>Descrição:</b> No caso de uma fusão, aquisição,
											reestruturação, venda de ativos ou falência, os dados dos
											usuários podem ser transferidos para a entidade sucessora
											ou parte adquirente como parte da transação.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										Em todos os casos de compartilhamento de dados, tomamos
										medidas adequadas para garantir que apenas as informações
										necessárias sejam compartilhadas e que a privacidade dos
										usuários seja protegida, cumprindo rigorosamente todas as
										leis e regulamentações aplicáveis.
									</p>
								</div>
							</section>
							<section
								id="retencao-dos-dados-dos-usuarios"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									7. Retenção dos Dados dos Usuários
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										A retenção de dados do usuário é realizada de forma
										criteriosa para garantir a segurança e privacidade,
										atendendo tanto às finalidades comerciais quanto às
										obrigações legais e regulatórias.
									</p>

									<p className="privacy-policy-paragraph">
										<b>7.1 Período de Retenção:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Retemos os dados pessoais dos usuários
											pelo período necessário para cumprir as finalidades
											descritas nesta Política de Privacidade, como a prestação
											de serviços, comunicação, análise de uso e melhoria dos
											nossos serviços.
										</li>
										<li>
											<b>Descrição:</b> Os dados pessoais são mantidos enquanto
											o usuário mantiver uma conta ativa na nossa plataforma,
											além de um período adicional conforme necessário para
											cumprir obrigações contratuais e legais.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.2 Conformidade Legal:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Retenção de dados para atender a
											requisitos legais e regulatórios.
										</li>
										<li>
											<b>Descrição:</b> Alguns dados podem ser retidos por
											períodos mais longos conforme exigido por leis
											específicas, regulamentos governamentais ou autoridades
											judiciais. Isso inclui dados relacionados a transações
											financeiras, registros contábeis e outros documentos
											necessários para auditorias e investigações.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.3 Resolução de Disputas e Execução de Contratos:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Manter dados para resolução de
											disputas, cumprimento de contratos e proteção contra
											fraudes.
										</li>
										<li>
											<b>Descrição:</b> Retemos dados pessoais conforme
											necessário para resolver disputas, aplicar nossos termos e
											condições e proteger nossos direitos legais, além de
											prevenir atividades fraudulentas e garantir a segurança
											dos nossos serviços e dos nossos usuários.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.4 Backup e Recuperação de Desastres:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Garantir a integridade e
											disponibilidade dos dados.
										</li>
										<li>
											<b>Descrição:</b> Dados pessoais podem ser armazenados em
											backups e sistemas de recuperação de desastres, sendo
											mantidos por períodos específicos para garantir a
											continuidade dos serviços em caso de falhas ou incidentes
											técnicos.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.5 Análise e Melhoria Contínua:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Utilização de dados para análise e
											melhoria contínua dos nossos serviços.
										</li>
										<li>
											<b>Descrição:</b> Dados anonimizados ou agregados podem
											ser retidos por períodos mais longos para fins de análise,
											pesquisa e desenvolvimento de novos produtos e serviços,
											garantindo que os insights obtidos contribuam para a
											evolução e aprimoramento da nossa plataforma.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.6 Exclusão e Anonimização de Dados:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Garantir a exclusão segura de dados
											após o período de retenção.
										</li>
										<li>
											<b>Descrição:</b> Após o término do período de retenção,
											os dados pessoais são excluídos de forma segura ou
											anonimizados para que não possam ser mais associados a um
											indivíduo específico. Implementamos políticas e
											procedimentos rigorosos para garantir que a exclusão de
											dados seja realizada de maneira eficaz e conforme as
											melhores práticas de segurança.
										</li>
									</ul>

									<p className="privacy-policy-paragraph">
										<b>7.7 Direitos dos Usuários:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Finalidade:</b> Facilitar o exercício dos direitos dos
											usuários em relação aos seus dados pessoais.
										</li>
										<li>
											<b>Descrição:</b> Os usuários têm o direito de solicitar a
											exclusão, correção ou restrição do processamento de seus
											dados pessoais a qualquer momento. Atendemos a essas
											solicitações conforme a legislação aplicável, garantindo
											que os dados sejam retidos apenas pelo tempo necessário e
											de acordo com os direitos e preferências dos usuários.
										</li>
									</ul>
								</div>
							</section>

							<section
								id="direitos-do-usuario"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									8. Direitos do Usuário
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										De acordo com a Lei Geral de Proteção de Dados Pessoais
										(LGPD), os usuários têm garantidos diversos direitos em
										relação aos seus dados pessoais. Esses direitos visam
										proporcionar maior transparência, controle e segurança na
										utilização de suas informações pessoais. Abaixo, detalhamos
										esses direitos e como podem ser exercidos:
									</p>
									<p className="privacy-policy-paragraph">
										<b>8.1 Confirmação e Acesso:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> O usuário tem o direito de confirmar a
											existência de tratamento dos seus dados pessoais por nossa
											empresa.
										</li>
										<li>
											<b>Ação:</b> Pode solicitar acesso aos seus dados pessoais
											que processamos, recebendo informações claras e completas
											sobre os dados e as atividades de tratamento realizadas.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.2 Correção:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de solicitar a correção de dados
											pessoais incorretos, inexatos ou desatualizados.
										</li>
										<li>
											<b>Ação:</b> Pode solicitar a atualização ou retificação
											de seus dados a qualquer momento, garantindo a precisão e
											a relevância das informações.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.3 Eliminação:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de solicitar a eliminação dos
											dados pessoais tratados com base no seu consentimento.
										</li>
										<li>
											<b>Ação:</b> Pode requerer a exclusão de seus dados
											pessoais, exceto nos casos em que a retenção seja
											necessária para cumprir obrigações legais, contratuais ou
											para resguardar direitos da empresa.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.4 Portabilidade:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de solicitar a portabilidade dos
											dados pessoais a outro fornecedor de serviço ou produto.
										</li>
										<li>
											<b>Ação:</b> Pode requerer que seus dados pessoais sejam
											transferidos, quando tecnicamente viável, a outra
											organização, mediante solicitação expressa e conforme
											regulamentação específica.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.5 Informação sobre Compartilhamento:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de obter informações sobre as
											entidades públicas e privadas com as quais compartilhamos
											seus dados pessoais.
										</li>
										<li>
											<b>Ação:</b> Pode solicitar detalhes sobre com quem seus
											dados foram compartilhados, incluindo a finalidade e a
											natureza desse compartilhamento.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.6 Revogação de Consentimento:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de revogar o consentimento dado
											para o tratamento de dados pessoais a qualquer momento.
										</li>
										<li>
											<b>Ação:</b> Pode retirar o consentimento previamente
											concedido, afetando apenas os tratamentos futuros e
											respeitando as obrigações legais e contratuais vigentes.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.7 Reclamação:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de apresentar uma reclamação à
											Autoridade Nacional de Proteção de Dados (ANPD) caso
											considere que seus direitos não foram adequadamente
											atendidos.
										</li>
										<li>
											<b>Ação:</b> Pode entrar em contato com a ANPD para
											registrar uma reclamação sobre a forma como seus dados
											pessoais são tratados.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.8 Oposição ao Tratamento:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de se opor ao tratamento de
											dados pessoais realizado com fundamento em uma das
											hipóteses de dispensa de consentimento.
										</li>
										<li>
											<b>Ação:</b> Pode manifestar oposição, solicitando a
											interrupção do tratamento de seus dados pessoais em
											determinados contextos, salvo se houver razões legítimas
											para a continuidade do tratamento.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>8.9 Anonimização, Bloqueio ou Eliminação:</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de solicitar a anonimização,
											bloqueio ou eliminação de dados pessoais desnecessários,
											excessivos ou tratados em desconformidade com a LGPD.
										</li>
										<li>
											<b>Ação:</b> Pode pedir que seus dados sejam tornados
											anônimos, bloqueados ou eliminados, quando o tratamento
											não estiver de acordo com as finalidades estabelecidas ou
											a legislação aplicável.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										<b>
											8.10 Informações sobre a Possibilidade de Não Consentir:
										</b>
									</p>
									<ul className="privacy-policy-unordered-list">
										<li>
											<b>Descrição:</b> Direito de ser informado sobre a
											possibilidade de não fornecer consentimento e sobre as
											consequências da negativa.
										</li>
										<li>
											<b>Ação:</b> Tem o direito de receber informações claras
											sobre as implicações de não conceder consentimento para o
											tratamento de seus dados pessoais, bem como sobre os
											serviços ou funcionalidades que podem ser afetados.
										</li>
									</ul>
									<p className="privacy-policy-paragraph">
										Para exercer quaisquer desses direitos, corrigir ou excluir
										dados pessoais, utilize as configurações disponíveis no
										painel de controle da plataforma, na área do cliente, ou
										entre em contato com nosso suporte através do e-mail:
										suporte@excofy.com. Estamos comprometidos em responder às
										solicitações de forma ágil e transparente, respeitando os
										prazos legais e as melhores práticas de proteção de dados.
									</p>
								</div>
							</section>

							<section
								id="seguranca-dos-dados-dos-usuarios"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									9. Segurança dos Dados dos Usuários
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Empregamos medidas de segurança apropriadas para proteger os
										dados pessoais dos usuários, incluindo tecnologias de
										criptografia e procedimentos rigorosos de controle de
										acesso. Nosso objetivo é garantir a integridade,
										confidencialidade e disponibilidade das informações dos
										usuários. No entanto, é importante ressaltar que, como
										qualquer sistema conectado à internet, sempre há um risco
										inerente de acesso não autorizado.
									</p>
									<p className="privacy-policy-paragraph">
										Por isso, incentivamos os usuários a adotarem práticas de
										segurança adicionais. É fundamental que o usuário proteja
										sua senha, escolhendo uma combinação robusta e única, e
										evite compartilhá-la com terceiros. Recomendamos também a
										atualização regular de senhas e a ativação de autenticação
										em duas etapas. Além disso, o usuário deve estar atento a
										atividades suspeitas em sua conta e revisar periodicamente
										seus registros de acesso.
									</p>
									<p className="privacy-policy-paragraph">
										Em caso de suspeita de acesso não autorizado ou qualquer
										outra atividade suspeita, o usuário deve entrar em contato
										conosco imediatamente através do e-mail suporte@excofy.com.
										Estamos comprometidos em investigar qualquer incidente
										reportado e tomar as medidas necessárias para proteger os
										dados dos nossos usuários. A colaboração dos usuários é
										crucial para manter a segurança de suas informações e
										garantir uma experiência segura na nossa plataforma.
									</p>
								</div>
							</section>

							<section
								id="politica-de-cookies"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									10. Política de Cookies
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Na EXCOFY, valorizamos a transparência e queremos assegurar
										que você esteja informado sobre o uso de cookies em nosso
										site. Os cookies são pequenos arquivos de texto que são
										armazenados em seu dispositivo quando você visita um site.
										Eles desempenham várias funções importantes para melhorar
										sua experiência de navegação e nos ajudar a manter e
										melhorar nossos serviços no futuro.
									</p>
									<p className="privacy-policy-paragraph">
										<b>10.1 Como Utilizamos os Cookies:</b>
									</p>
									<p className="privacy-policy-paragraph">
										<ol className="privacy-policy-ordered-list">
											<li>
												<b>Funcionalidade Essencial:</b> Utilizamos cookies para
												garantir que certas funcionalidades essenciais do site
												funcionem corretamente, como manter suas preferências de
												idioma ou facilitar o login em sua conta.
											</li>
											<li>
												<b>Análise de Desempenho:</b> Empregamos software de
												análise para entender como os visitantes interagem com
												nosso site. Isso nos permite melhorar continuamente
												nosso conteúdo e design para proporcionar uma
												experiência mais eficiente e relevante.
											</li>
											<li>
												<b>Análise de Terceiros:</b> Para obter insights sobre
												como nosso site é utilizado, utilizamos serviços de
												análise de terceiros, como Google Analytics. Isso nos
												ajuda a entender melhor o comportamento dos visitantes e
												a tomar decisões informadas para melhorias futuras.
											</li>
											<li>
												<b>Promoções de Afiliados:</b> Em algumas páginas, você
												pode encontrar links para serviços de afiliados. Se você
												clicar e realizar uma compra através desses links,
												podemos receber uma pequena comissão. Esses cookies de
												afiliados ajudam a rastrear as vendas originadas de
												nosso site e são fundamentais para manter nossa operação
												e oferecer conteúdo relevante no futuro.
											</li>
										</ol>
									</p>
									<p className="privacy-policy-paragraph">
										<b>10.2 Como Gerenciar Cookies:</b>
									</p>
									<p className="privacy-policy-paragraph">
										Você pode controlar o uso de cookies através das
										configurações do seu navegador. Note que desabilitar cookies
										pode afetar a funcionalidade e experiência de navegação em
										nosso site.
									</p>
									<p className="privacy-policy-paragraph">
										Esta política de cookies está alinhada com nossos
										compromissos de proteção de dados e é projetada para
										garantir que sua experiência em nosso site seja segura,
										eficaz e adaptada às suas necessidades.
									</p>
								</div>
							</section>

							<section
								id="alteracoes-na-politica-de-privacidade"
								className="privacy-policy--section"
							>
								<h2 className="privacy-policy--subtitle">
									11. Alterações na Política de Privacidade
								</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Podemos atualizar esta Política de Privacidade
										periodicamente para refletir mudanças em nossas práticas de
										tratamento de dados, requisitos legais ou melhorias nos
										nossos serviços. Qualquer alteração significativa será
										publicada em nosso site, juntamente com a data de revisão
										atualizada, para manter os usuários informados sobre como
										estamos protegendo suas informações.
									</p>
									<p className="privacy-policy-paragraph">
										Recomendamos que os usuários revisem nossa Política de
										Privacidade regularmente para se manterem atualizados sobre
										nossas práticas e quaisquer mudanças que possam ocorrer.
										Manter-se informado sobre estas atualizações é crucial para
										entender os direitos e responsabilidades do usuário em
										relação aos seus dados pessoais.
									</p>
									<p className="privacy-policy-paragraph">
										Se realizarmos alterações substanciais que afetem os
										direitos dos usuários, forneceremos um aviso mais destacado,
										seja através de uma notificação em nosso site, uma
										notificação por e-mail ou outro meio de comunicação
										apropriado. Estas medidas visam garantir que os usuários
										tenham a oportunidade de revisar as mudanças e, se
										necessário, ajustar suas configurações de privacidade ou
										entrar em contato conosco para esclarecer dúvidas.
									</p>
									<p className="privacy-policy-paragraph">
										O uso contínuo de nossos serviços após a publicação das
										alterações constitui aceitação da Política de Privacidade
										revisada.{' '}
									</p>
								</div>
							</section>

							<section id="contato" className="privacy-policy--section">
								<h2 className="privacy-policy--subtitle">12. Contato</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Se o usuário tiver dúvidas sobre esta Política de
										Privacidade, pode entrar em contato conosco pelo e-mail
										privacidade@excofy.com
									</p>
								</div>
							</section>

							<section id="vigencia" className="privacy-policy--section">
								<h2 className="privacy-policy--subtitle">13. Vigência</h2>
								<div className="privacy-policy--section-content">
									<p className="privacy-policy-paragraph">
										Esta Política de Privacidade entra em vigor a partir da data
										de sua publicação no site da EXCOFY.
									</p>
								</div>
							</section>
							<p className="terms-of-use--tag">Atualizado em 11/10/2024</p>
						</div>
					</div>
					<Footer />
				</div>
			</main>
		</div>
	)
}

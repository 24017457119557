import {Logo} from '../logo-type'
import {SignUpButton} from '../signup-button'
import {MenuList} from '../menu-list'

import './styles.css'

export const Header = () => {
	return (
		<header>
			<div className="header--container">
				<Logo />
				<div>
					<div className="header--buttons-container">
						<LoginButton />
						<SignUpButton />
					</div>
					<MenuList />
				</div>
			</div>
		</header>
	)
}

const LoginButton = () => {
	return (
		<div className="light-button">
			<a href="https://dashboard.excofy.com/pt/login">
				<span>ENTRAR</span>
			</a>
		</div>
	)
}

import './styles.css'

export const Logo = () => {
	return (
		<a
			href="/"
			className="logo"
			style={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<img src="/logo-excofy.svg" width={60} height={60} alt="Excofy Logo" />
			<h1>Excofy</h1>
		</a>
	)
}

export const LogoType = () => {
	return (
		<div className="logo-type" style={{display: 'none'}}>
			<img src="/logo-excofy.svg" width={60} height={60} alt="Excofy Logo" />
		</div>
	)
}

export const ContactButton = ({onClick}) => {
	return (
		<button
			onClick={onClick}
			style={{
				cursor: 'pointer',
				backgroundColor: '#FFF',
				borderRadius: 4,
				border: '1px solid #757575',
				padding: '8px 22px',
				height: 44,
				fontWeight: 500,
				display: 'flex',
				alignItems: 'center',
				color: '#424242'
			}}
		>
			ENTRAR EM CONTATO
		</button>
	)
}

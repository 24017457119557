import React from 'react'
import Marquee from 'react-fast-marquee'

export const IntegrationsCarousel = () => {
	return (
		<div className="slider-container">
			<Marquee gradient gradientWidth={100} loop={0} delay={0} speed={30}>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529271/platform/integrations/pagarme-cover.png"
							alt="image322903"
							className="frame1-home-image32"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1728646421/platform/integrations/asaas-cover.png"
							alt="image322903"
							className="frame1-home-image32"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/cademi-cover.png"
							alt="image352903"
							className="frame1-home-image35"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/the-members-cover.png"
							alt="image322903"
							className="frame1-home-image32"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/meta-cover.png"
							alt="image342903"
							className="frame1-home-image34"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/whatsapp-checkout-cover.png"
							alt="image342903"
							className="frame1-home-image34"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/order-bump-cover.png"
							alt="image332903"
							className="frame1-home-image33"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
				<div className="slide-item-container">
					<div className="slide-item">
						<img
							src="https://res.cloudinary.com/excofy-production/image/upload/v1719529186/platform/integrations/upsell-cover.png"
							alt="image322903"
							className="frame1-home-image32"
							style={{
								borderRadius: '8px'
							}}
						/>
					</div>
				</div>
			</Marquee>
		</div>
	)
}

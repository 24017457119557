import {Header} from '../../components/header/index'
import {Sidebar} from '../../components/sidebar/index'
import {Footer} from '../../components/footer/index'

import './styles.css'

export default function TermsOfUse() {
	return (
		<div id="terms-of-use">
			<Header />
			<main>
				<Sidebar
					items={[
						{label: 'Introdução', link: '#title', selected: true},
						{
							label: 'Sobre a Plataforma Excofy',
							link: '#sobre-a-plataforma-excofy',
							selected: false
						},
						{
							label: 'Aceitação dos Termos de Uso',
							link: '#aceitacao-dos-termos-de-uso',
							selected: false
						},
						{
							label: 'Condições de Uso',
							link: '#condicoes-de-uso',
							selected: false
						},
						{
							label: 'Contratação do Serviço',
							link: '#contratacao-de-servico',
							selected: false
						},
						{
							label: 'Direitos de Propriedade Intelectual',
							link: '#direitos-de-propriedade-intelectual',
							selected: false
						},
						{
							label: 'Atualizações da Plataforma',
							link: '#atualizacoes-da-plataforma',
							selected: false
						},
						{
							label: 'Responsabilidades do Usuário',
							link: '#responsabilidades-do-usuario',
							selected: false
						},
						{
							label: 'Responsabilidades da Excofy',
							link: '#responsabilidades-da-excofy',
							selected: false
						},
						{
							label: 'Exoneração de Garantia e Limitação de Responsabilidade',
							link: '#exoneracao-de-garantia-e-limitacao-de-responsabilidades',
							selected: false
						},
						{
							label: 'Isenção de Responsabilidade sobre Integrações',
							link: '#isencao-de-responsabilidade-sobre-integracoes',
							selected: false
						},
						{
							label: 'Remoção de Conteúdo Ilegal',
							link: '#remocao-de-conteudo-ilegal',
							selected: false
						},
						{label: 'Privacidade', link: '#privacidade', selected: false},
						{
							label: 'Lei Aplicável e Foro',
							link: '#lei-aplicavel-e-foro',
							selected: false
						},
						{
							label: 'Aspectos Gerais',
							link: '#aspectos-gerais',
							selected: false
						},
						{label: 'Idioma', link: '#idioma', selected: false}
					]}
				/>
				<div className="terms-of-use--content">
					<div className="terms-of-use--container">
						<h1 id="title">Termos de Uso</h1>
						<div className="terms-of-use--sections-container">
							<section id="section1" className="terms-of-use--section">
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										<b>Bem-vindo(a) a Excofy!</b>
									</p>
									<p className="terms-of-use-paragraph">
										A Excofy Tecnologia e Soluções Digitais LTDA, registrada sob
										o CNPJ 55.390.780/0001-15, tem o prazer de apresentar a você
										os <b>Termos de Uso</b> da nossa plataforma EXCOFY. Nossa
										missão é apoiar empresas de todos os portes e segmentos a
										alcançar um processo de venda online mais organizado e
										produtivo.
									</p>
									<p className="terms-of-use-paragraph">
										Nosso compromisso é garantir a transparência e a clareza em
										todas as nossas interações com você, nosso usuário. A EXCOFY
										valoriza a confiança que você deposita em nós e trabalha
										continuamente para melhorar a experiência do usuário,
										assegurar a proteção de seus dados e oferecer suporte de
										alta qualidade.
									</p>
								</div>
							</section>
							<section
								id="sobre-a-plataforma-excofy"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Sobre a Plataforma Excofy
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										A plataforma EXCOFY foi desenvolvida para proporcionar uma
										experiência otimizada na gestão de vendas online, integrando
										funcionalidades que abrangem desde a captação de clientes em
										páginas de vendas até o controle de faturamento. Acreditamos
										que, ao fornecer ferramentas intuitivas e eficientes,
										podemos contribuir significativamente para o sucesso do seu
										negócio
									</p>
									<p className="terms-of-use-paragraph">
										Oferecemos ferramentas para a criação de páginas de venda
										personalizadas, processos de pagamentos, gestão de clientes
										e vendas, além de diversas integrações para otimizar suas
										operações comerciais.
									</p>
								</div>
							</section>
							<section
								id="aceitacao-dos-termos-de-uso"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Aceitação dos Termos de Uso
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Ao utilizar a plataforma EXCOFY, você declara estar ciente e
										de acordo com os Termos de Uso aqui apresentados. Este
										documento constitui um contrato legal entre você e a EXCOFY,
										definindo as diretrizes para a utilização de nossos
										serviços. Caso você não concorde com algum dos termos ou
										condições estabelecidos, recomendamos que não utilize a
										plataforma.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY reserva-se o direito de alterar, modificar,
										adicionar ou remover partes destes Termos de Uso a qualquer
										momento. Informaremos sobre quaisquer mudanças
										significativas através de notificações na plataforma ou por
										outros meios de comunicação. É sua responsabilidade revisar
										periodicamente este documento para estar ciente de quaisquer
										atualizações. O uso contínuo da plataforma após a publicação
										de alterações constitui sua aceitação dessas mudanças.
									</p>
									<p className="terms-of-use-paragraph">
										Agradecemos por escolher a EXCOFY. Estamos aqui para apoiar
										sua jornada de vendas online.
									</p>
								</div>
							</section>
							<section id="condicoes-de-uso" className="terms-of-use--section">
								<h2 className="terms-of-use--subtitle">Condições de Uso</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										A EXCOFY fornecerá ao USUÁRIO acesso aos Serviços através do
										domínio excofy.com e de seus aplicativos nas condições
										expostas no presente Termo de Uso.
									</p>
									<p className="terms-of-use-paragraph">
										O Serviço será fornecido mediante registro de conta. A conta
										será vinculada a um USUÁRIO que receberá no seu e-mail um
										código para ativá-la no sistema. O USUÁRIO é responsável por
										manter a confidencialidade das informações de acesso à CONTA
										e por todas as atividades que ocorram na mesma.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY poderá, a seu exclusivo critério, limitar,
										suspender ou encerrar o acesso do USUÁRIO aos Serviços, sem
										aviso prévio, caso o USUÁRIO viole estes Termos de Uso ou
										qualquer outra política aplicável, ou ainda por motivos de
										segurança.
									</p>
								</div>
							</section>
							<section
								id="contratacao-de-servico"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Contratação do Serviço
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Para utilizar os Serviços da EXCOFY, o USUÁRIO deve criar
										uma conta e concordar com os presentes Termos de Uso. O
										cadastro na plataforma é gratuito.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY cobra 3% de comissão sobre o valor bruto das vendas
										realizadas. Essa comissão será automaticamente deduzida do
										valor das vendas antes de ser transferido para o USUÁRIO.
									</p>
									<p className="terms-of-use-paragraph">
										Para processar os pagamentos, o USUÁRIO deve ativar SERVIÇOS
										DE PAGAMENTO INTEGRADOS com a plataforma, tais como
										Pagar.me, Asaas, Mercado Pago ou outros que possam ser
										disponibilizados. As taxas e encargos dos serviços de
										pagamento serão automaticamente deduzidas do valor total das
										vendas ou na solicitação de transferência de saldos para
										conta bancárias antes de ser transferida para o USUÁRIO. As
										taxas dos serviços de pagamento são independentes das taxas
										do EXCOFY.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por todas as taxas e encargos
										associados ao uso dos SERVIÇOS DE PAGAMENTO INTEGRADOS,
										incluindo, mas não se limitando a, comissões sobre vendas,
										taxas de processamento de pagamento, e quaisquer impostos
										aplicáveis. A EXCOFY não se responsabiliza por quaisquer
										valores adicionais cobrados por serviços de pagamento ou
										instituições financeiras integradas.
									</p>
									<p className="terms-of-use-paragraph">
										Iremos notificar os USUÁRIOS sobre qualquer alteração nas
										taxas do SERVIÇOS DE PAGAMENTO INTEGRADOS com a maior
										antecedência possível. No entanto, os valores das taxas de
										serviço podem ser alterados pelos serviços de pagamento a
										qualquer momento.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO concorda em fornecer informações bancárias
										precisas e atualizadas para o recebimento de valores aos
										serviços de pagamento. A EXCOFY não será responsável por
										quaisquer atrasos ou falhas na transferência de valores
										devido a informações bancárias incorretas ou quaisquer
										problemas no serviços de pagamentos integrados.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO pode acompanhar os valores das taxas e encargos
										dos SERVIÇOS DE PAGAMENTO INTEGRADOS na opção "Serviços de
										Pagamento" da plataforma.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO pode cancelar sua conta a qualquer momento, em
										suas configurações de Conta na plataforma. No caso de
										cancelamento, o USUÁRIO permanecerá responsável por quitar
										comissões e taxas devidas à EXCOFY ou aos SERVIÇOS DE
										PAGAMENTO INTEGRADOS.
									</p>
								</div>
							</section>
							<section
								id="direitos-de-propriedade-intelectual"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Direitos de Propriedade Intelectual
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Todos os direitos de propriedade intelectual relacionados à
										plataforma EXCOFY, incluindo, mas não se limitando a,
										software, design, tecnologia, algoritmos, códigos fonte,
										interfaces, funcionalidades, marcas, logotipos, nomes
										comerciais e quaisquer outras propriedades industriais ou
										intelectuais, são de titularidade exclusiva da EXCOFY ou de
										seus licenciadores.
									</p>
									<p className="terms-of-use-paragraph">É vedado ao USUÁRIO:</p>
									<ul className="terms-of-use-unordered-list">
										<li>
											Copiar, modificar, criar obras derivadas, fazer engenharia
											reversa, descompilar ou tentar de qualquer outra forma
											extrair o código fonte da plataforma ou de qualquer parte
											dela;
										</li>
										<li>
											Utilizar qualquer processo manual ou automatizado para
											monitorar ou copiar o conteúdo ou dados da plataforma;
										</li>
										<li>
											Utilizar os Serviços para construir um produto ou serviço
											concorrente ou copiar qualquer recurso, função ou gráfico
											dos Serviços;
										</li>
										<li>
											Remover, ocultar ou alterar quaisquer avisos de direitos
											autorais, marcas registradas ou outros avisos de
											propriedade exibidos na plataforma.
										</li>
									</ul>
									<p className="terms-of-use-paragraph">
										Caso o USUÁRIO infrinja quaisquer direitos de propriedade
										intelectual da EXCOFY ou de terceiros, a EXCOFY poderá, a
										seu exclusivo critério, limitar, suspender ou encerrar a
										conta do USUÁRIO e tomar as medidas legais cabíveis para
										proteger seus direitos.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY reserva-se o direito de, a qualquer momento,
										modificar ou descontinuar, temporariamente ou
										permanentemente, quaisquer funcionalidades ou
										características da plataforma que, a seu exclusivo critério,
										considerar necessário para a proteção de seus direitos de
										propriedade intelectual.
									</p>
									<p className="terms-of-use-paragraph">
										Quaisquer feedbacks, comentários, sugestões ou ideias
										fornecidos pelo USUÁRIO à EXCOFY com relação à plataforma
										são de propriedade exclusiva da EXCOFY. A EXCOFY pode, a seu
										exclusivo critério, publicar o feedback para qualquer
										propósito e sem qualquer restrição ou compensação ao
										USUÁRIO.
									</p>
								</div>
							</section>
							<section
								id="atualizacoes-da-plataforma"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Atualizações da Plataforma
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										A EXCOFY está continuamente trabalhando para melhorar e
										aprimorar a plataforma, e pode, a seu exclusivo critério,
										fornecer atualizações, melhorias, correções de bugs ou novas
										versões da plataforma ("Atualizações").
									</p>
									<p className="terms-of-use-paragraph">
										As Atualizações serão implantadas na plataforma
										automaticamente, sem qualquer aviso prévio ao USUÁRIO. A
										EXCOFY envidará esforços razoáveis para informar o USUÁRIO
										sobre as mudanças significativas decorrentes das
										Atualizações, por meio de notificações no site, e-mails ou
										outros meios de comunicação adequados.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY reserva-se o direito de, a qualquer momento,
										modificar ou descontinuar, temporariamente ou
										permanentemente, os Serviços (ou qualquer parte deles)
										mediante aviso prévio. O USUÁRIO concorda que a EXCOFY não
										será responsável perante ele ou qualquer terceiro por
										prejuízos decorrentes de qualquer modificação, suspensão ou
										descontinuação dos Serviços.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por manter seus dispositivos e
										sistemas compatíveis e atualizados para garantir o
										funcionamento adequado da plataforma após as Atualizações. A
										EXCOFY não se responsabiliza por problemas de
										compatibilidade decorrentes de Atualizações.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO reconhece e concorda que certas Atualizações podem
										ser necessárias para manter a conformidade com a legislação
										aplicável, melhorar a segurança da plataforma ou introduzir
										novos recursos e funcionalidades, e que a recusa ou falha em
										instalar tais Atualizações pode resultar na
										indisponibilidade dos Serviços ou na limitação de certas
										funcionalidades.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY poderá, a seu exclusivo critério, decidir
										descontinuar o suporte a versões mais antigas da plataforma
										após a disponibilização de Atualizações. Neste caso, o
										USUÁRIO será notificado com antecedência razoável.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY não será responsável por quaisquer perdas ou danos
										decorrentes de falhas, erros, interrupções, mudanças ou
										descontinuações na plataforma, decorrentes da implementação
										de Atualizações.
									</p>
								</div>
							</section>
							<section
								id="responsabilidades-do-usuario"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Responsabilidades do Usuário
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por todas as atividades realizadas
										por meio de sua conta na plataforma EXCOFY, devendo
										assegurar que todas as informações fornecidas no momento do
										registro e durante o uso dos Serviços sejam precisas,
										completas e atualizadas.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO deve manter a confidencialidade de suas
										credenciais de acesso, não as compartilhando com terceiros.
										Qualquer uso não autorizado de sua conta deve ser
										imediatamente comunicado à EXCOFY.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO concorda em utilizar a plataforma apenas para fins
										legais e legítimos, em conformidade com todas as leis,
										regulamentos e normas aplicáveis. É vedado ao USUÁRIO:
									</p>
									<ul className="terms-of-use-unordered-list">
										<li>
											Utilizar a plataforma para fins fraudulentos, ilícitos ou
											em desacordo com estes Termos de Uso;
										</li>
										<li>
											Publicar, transmitir ou de qualquer forma disponibilizar
											conteúdo que seja ilegal, prejudicial, ameaçador, abusivo,
											ofensivo, difamatório, obsceno ou de outra forma
											censurável;
										</li>
										<li>
											Violar direitos de propriedade intelectual, privacidade ou
											outros direitos de terceiros;
										</li>
										<li>
											Interferir ou interromper a operação da plataforma,
											servidores ou redes conectadas à plataforma, incluindo a
											disseminação de vírus, malware ou outros códigos nocivos;
										</li>
										<li>
											Tentar obter acesso não autorizado à plataforma, outras
											contas, sistemas ou redes conectadas à plataforma, por
											meio de hacking, phishing ou qualquer outro meio.
										</li>
									</ul>
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por manter seu ambiente de
										tecnologia da informação seguro, adotando ferramentas como
										antivírus, para contribuir na prevenção de riscos
										eletrônicos.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO deve se abster de utilizar a plataforma para a
										realização de atividades que possam resultar em
										responsabilidade civil ou criminal, como a venda de produtos
										falsificados, não autorizados ou que infrinjam direitos de
										terceiros.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por todas as obrigações fiscais
										decorrentes das transações realizadas através da plataforma,
										incluindo, mas não se limitando a, impostos sobre vendas,
										taxas e encargos aplicáveis.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO concorda em indenizar e isentar a EXCOFY, seus
										colaboradores, representantes e afiliados de qualquer
										reivindicação, demanda, ação judicial, dano, perda,
										responsabilidade, custo ou despesa, incluindo honorários
										advocatícios, decorrentes de ou relacionados ao uso
										inadequado da plataforma, violação destes Termos de Uso, ou
										violação de quaisquer direitos de terceiros.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO reconhece que é o único responsável pelo conteúdo
										que publica, transmite ou disponibiliza através da
										plataforma, bem como por garantir que este conteúdo não
										infrinja direitos de terceiros ou viole quaisquer leis ou
										regulamentos aplicáveis.
									</p>
								</div>
							</section>
							<section
								id="responsabilidades-da-excofy"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Responsabilidades da Excofy
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Envidaremos esforços razoáveis para manter a plataforma
										disponível e operando de maneira eficiente e segura.
										Contudo, podem ocorrer interrupções temporárias para
										manutenção, atualizações ou melhorias técnicas, e a EXCOFY
										não será responsável por qualquer interrupção ou perda de
										dados durante tais períodos.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY se compromete a fornecer suporte técnico aos
										USUÁRIOS da plataforma. O suporte será oferecido através dos
										canais indicados no site oficial da EXCOFY e estará
										disponível durante os horários especificados. Nossa equipe
										de suporte se esforçará para resolver quaisquer problemas
										técnicos no menor tempo possível.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY se compromete a operar em conformidade com todas as
										leis e regulamentos aplicáveis, incluindo, mas não se
										limitando a, leis de proteção de dados e privacidade.
										Envidaremos esforços para assegurar que nossos serviços não
										sejam utilizados de maneira ilegal ou para qualquer
										propósito proibido por estes Termos de Uso.
									</p>
								</div>
							</section>
							<section
								id="exoneracao-de-garantia-e-limitacao-de-responsabilidades"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Exoneração de Garantia e Limitação de Responsabilidade
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										A EXCOFY fornece a plataforma "no estado em que se encontra"
										e "conforme disponível", sem garantias de qualquer tipo,
										sejam expressas ou implícitas. Isso inclui, mas não se
										limita a, garantias de comercialização, adequação a um
										propósito específico, e não violação. Por envolver{' '}
										<b>serviços de terceiros</b> em sua operação, a EXCOFY não
										garante que o serviço será ininterrupto, seguro ou livre de
										erros, ou que os resultados obtidos pelo uso da plataforma
										serão precisos.
									</p>
									<p className="terms-of-use-paragraph">
										Na máxima extensão permitida por lei, em nenhuma hipótese a
										EXCOFY, seus diretores, funcionários, parceiros,
										fornecedores ou afiliados serão responsáveis por quaisquer
										danos indiretos, incidentais, especiais, consequenciais ou
										punitivos, incluindo, mas não se limitando a, perda de
										lucros, dados, uso, fundo de comércio ou outras perdas
										intangíveis, resultantes de:
									</p>
									<ul className="terms-of-use-unordered-list">
										<li>
											Seu acesso ou uso ou incapacidade de acessar ou usar a
											plataforma;
										</li>
										<li>
											Qualquer conduta ou conteúdo de terceiros na plataforma;
										</li>
										<li>Qualquer conteúdo obtido da plataforma;</li>
										<li>
											Acesso, uso ou alteração não autorizados de suas
											transmissões ou conteúdo.
										</li>
									</ul>
									<p className="terms-of-use-paragraph">
										Em qualquer caso, a responsabilidade total da EXCOFY por
										todas as reclamações relacionadas à plataforma não excederá
										o valor que você pagou, se houver, para usar os serviços da
										EXCOFY durante os três meses anteriores à ocorrência do
										evento que gerou a responsabilidade.
									</p>
								</div>
							</section>
							<section
								id="isencao-de-responsabilidade-sobre-integracoes"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Isenção de Responsabilidade sobre Integrações
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										A EXCOFY oferece a possibilidade de integrar a plataforma
										com serviços de terceiros, incluindo os SERVIÇOS DE
										PAGAMENTO INTEGRADOS, para aprimorar e expandir as
										funcionalidades disponíveis aos USUÁRIOS. No entanto, a
										EXCOFY não se responsabiliza pela qualidade, desempenho,
										disponibilidade ou confiabilidade dos serviços de terceiros
										integrados à plataforma.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO reconhece e concorda que o uso de serviços de
										terceiros integrados é de sua exclusiva responsabilidade.
										Qualquer configuração e controle dessas integrações são
										realizados pelo USUÁRIO, que deve assegurar-se de que tais
										serviços atendem às suas necessidades e expectativas.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO é responsável por garantir que as integrações com
										serviços de terceiros sejam realizadas de acordo com as
										leis, regulamentos e normas aplicáveis, bem como pelos
										termos e condições estabelecidos pelos próprios prestadores
										desses serviços.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY não oferece suporte técnico ou assistência para a
										configuração ou uso de serviços de terceiros integrados,
										sendo o USUÁRIO responsável por buscar suporte diretamente
										com os prestadores desses serviços quando necessário.
									</p>
									<p className="terms-of-use-paragraph">
										Tentaremos ao máximo comunicar os USUÁRIOS com antecedência
										sobre qualquer modificação, remoção ou descontinuidade de
										integrações. No entanto, a EXCOFY reserva-se o direito de, a
										qualquer momento e a seu exclusivo critério, descontinuar ou
										modificar as integrações com serviços de terceiros, com ou
										sem aviso prévio ao USUÁRIO. A EXCOFY não será responsável
										por qualquer prejuízo ou dano causado pela modificação,
										suspensão ou descontinuação de integrações.
									</p>
									<p className="terms-of-use-paragraph">
										O USUÁRIO concorda em isentar a EXCOFY de qualquer
										responsabilidade, reclamação, demanda, ação judicial, dano,
										perda, custo ou despesa, incluindo honorários advocatícios,
										decorrentes do uso de serviços de terceiros integrados à
										plataforma.
									</p>
								</div>
							</section>
							<section
								id="remocao-de-conteudo-ilegal"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">
									Remoção de Conteúdo Ilegal
								</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										O EXCOFY respeita os direitos de terceiros. Se você tiver
										qualquer reclamação ou objeção ao conteúdo publicado por
										algum Usuário, ou acreditar que o conteúdo publicado na
										EXCOFY infrinjam seus direitos de propriedade intelectual,
										pedimos que nos contate imediatamente através do e-mail
										copyright@excofy.com, fornecendo seu nome completo,
										documento de identidade, e todas as evidências possíveis que
										comprovem sua propriedade do conteúdo. Uma vez recebida a
										sua reclamação, iremos verificar as evidências e colocaremos
										nossos esforços para remover o conteúdo ilegal dentro de um
										período razoável de tempo.
									</p>
									<p className="terms-of-use-paragraph">
										A EXCOFY disponibilizará todos os seus esforços para remover
										conteúdo gerado por terceiros sem autorização de seus
										participantes, de imagens, vídeos ou de outros materiais
										contendo cenas caráter privado, após o recebimento de
										notificação pelo participante ou seu representante legal. A
										notificação prevista deverá conter, sob pena de nulidade,
										elementos que permitam a identificação específica do
										material apontado como violador da intimidade do
										participante e a verificação da legitimidade para
										apresentação do pedido.
									</p>
								</div>
							</section>
							<section id="privacidade" className="terms-of-use--section">
								<h2 className="terms-of-use--subtitle">Privacidade</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Toda e qualquer informação publicada por você na EXCOFY será
										usada em conformidade com a nossa{' '}
										<a href="privacy-policy" className="link">
											Política de Privacidade
										</a>
										.
									</p>
								</div>
							</section>
							<section
								id="lei-aplicavel-e-foro"
								className="terms-of-use--section"
							>
								<h2 className="terms-of-use--subtitle">Lei Aplicável e Foro</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Estes termos são regidos pelas leis brasileiras e quaisquer
										disputas oriundas destes Termos de Uso deverão ser
										processadas no foro da cidade de São Paulo.
									</p>
								</div>
							</section>
							<section id="aspectos-gerais" className="terms-of-use--section">
								<h2 className="terms-of-use--subtitle">Aspectos Gerais</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										Se qualquer cláusula destes termos for tida como inválida ou
										inexequível em conformidade com a lei aplicável, esta
										cláusula será substituída por uma que se equipare da melhor
										maneira possível à intenção original, sendo que todas as
										demais cláusulas continuarão em vigor.
									</p>
								</div>
							</section>
							<section id="idioma" className="terms-of-use--section">
								<h2 className="terms-of-use--subtitle">Idioma</h2>
								<div className="terms-of-use--section-content">
									<p className="terms-of-use-paragraph">
										O idioma da versão original deste acordo é o Português
										(Brasil). Em caso de conflito de qualquer versão traduzida
										deste acordo com a versão em Português, a versão em
										Português prevalecerá.
									</p>
								</div>
							</section>
							<p className="terms-of-use--tag">Atualizado em 11/10/2024</p>
						</div>
					</div>
					<Footer />
				</div>
			</main>
		</div>
	)
}
